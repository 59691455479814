<template>
  <VDataTable
    :headers="headers"
    :items="users"
  >
    <template #item.actions="{ item }">
      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            depressed
            small
            color="primary"
            class="mr-2"
            v-bind="attrs"
            :to="{
              name : Names.R_META,
              params : {
                userId : item.id,
                serviceId : item.serviceId
              }
            }"
            v-on="on"
          >
            <VIcon small>
              fal fa-eye
            </VIcon>
          </VBtn>
        </template>

        <span>Просмотр</span>
      </VTooltip>
      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            depressed
            small
            color="warning"
            v-bind="attrs"
            :to="{
              name : Names.R_META_EDIT,
              params : {
                userId : item.id,
                serviceId : item.serviceId
              }
            }"
            v-on="on"
          >
            <VIcon small>
              fal fa-edit
            </VIcon>
          </VBtn>
        </template>

        <span>Редактировать</span>
      </VTooltip>
    </template>
  </VDataTable>
</template>

<script>
export default {
  name: 'MetaSearchResultTable',
  inject: ['Names'],
  props: {
    users: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'UUID',
          value: 'id',
        },
        {
          text: 'Service ID',
          value: 'serviceId',
        },
        {
          text: '',
          align: 'right',
          value: 'actions',
        },
      ],
    };
  },
};
</script>
