<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VToolbar flat>
            <VToolbarTitle>
              Поиск метаинформации
            </VToolbarTitle>

            <VSpacer />

            <VBtn
              depressed
              color="primary"
              :to="{ name : Names.R_META_CREATE }"
            >
              <VIcon left>
                fal fa-plus
              </VIcon>

              Создать новую мета
            </VBtn>
          </VToolbar>

          <VDivider />

          <VCardText>
            <VRow class="mb-5">
              <VCol cols="6">
                <VTextField
                  v-model="userId"
                  name="userId"
                  filled
                  label="UUID"
                />
                <VTextField
                  v-model="serviceId"
                  name="serviceId"
                  filled
                  label="Service ID"
                />
                <VBtn
                  depressed
                  color="primary"
                  large
                  @click="findMeta"
                >
                  Найти
                </VBtn>
              </VCol>
            </VRow>

            <VRow v-if="metas.length">
              <VCol>
                <MetaSearchResultTable :users="metas" />
              </VCol>
            </VRow>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import MetaSearchResultTable from '../../components/meta/MetaSearchResultTable.vue';

export default {
  name: 'MetaSearch',
  components: { MetaSearchResultTable },
  inject: ['Names'],
  data() {
    return {
      userId: '',
      serviceId: '',
      metas: [],
    };
  },
  methods: {
    findMeta() {
      const payload = {
        id: this.userId,
        serviceId: this.serviceId,
      };

      this.$di.api.Meta
        .getMeta(payload)
        .then((response) => {
          const result = {
            ...payload,
            meta: response.meta,
          };

          this.metas = [result];

          this.clearFields();
        })
        .catch(this.$di.notify.errorHandler);
    },
    clearFields() {
      this.uuid = '';
      this.serviceId = '';
    },
  },
};
</script>
